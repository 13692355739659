@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,400;0,700;1,300&display=swap');

@layer components{
  .label{
    @apply roboto mb-2 text-sm font-semibold inline-block text-gray-700
  }
  .input{
    @apply w-full border roboto focus:outline-none focus-within:shadow px-3 py-2 rounded placeholder:italic placeholder:text-sm
  }
  .text_area{
    @apply border roboto min-h-[6rem] rounded px-3 py-2 focus:outline-none focus-within:shadow placeholder:italic placeholder:text-sm w-full
  }
  .mandatory{
    @apply text-xl text-red-800
  }
}
.roboto {
  font-family: 'Raleway', sans-serif;
  font-feature-settings: 'lnum' 1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.document-body::-webkit-scrollbar{
  display: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input[disabled]{background-color: white;}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}